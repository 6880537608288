/* Loading Container */
/* Ensure the HTML, body, and root element occupy full height */
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Flex container for the entire app */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

/* Main content expands to fill available space */
.main-content {
  flex: 1;
  margin-top: 100px;
}

.loading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
  text-align: center;
  margin-top: 20%;
  animation: colorChange 2s infinite, spin 1.5s infinite linear;
}

/* Color animation */
@keyframes colorChange {
  0%,
  100% {
    color: #007bff;
  }
  50% {
    color: #ff6f61;
  }
}

/* Spin effect */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.component-heading {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
  text-align: center;
}
