.contactParent {
  /* Optionally keep your background image commented out if you wish
       background-image: url(../public/CN1.jpg); 
    */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  /* So we can position our phone icon pseudo-element */
  position: relative;
  overflow: hidden;
}

/* 
    A big phone icon (Unicode \260E) pulsing infinitely.
    You can adjust the font-size, color, or animation as you like.
  */
.contactParent::before {
  content: "\260E"; /* The phone icon */
  position: absolute;
  top: 30%;
  left: 50%;
  font-size: 12rem;
  color: rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
  animation: phonePulse 3s ease-in-out infinite; /* infinite pulsing */
}

/* The pulsing animation keyframes */
@keyframes phonePulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

#contact {
  margin: 0 auto;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
}

.googleMapParent {
  width: 100%;
  height: 450px;
  margin-top: 10%;
}

.mapLoading {
  display: flex;
  font-size: x-large;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 1);
  border-radius: 10px;
  transform: rotateX(20deg) translateZ(30px);
}

.mapLoadingNone {
  display: none;
}

.googleMap {
  width: 100%;
  height: 0px;
  transform: scale(0, 0);
}

.googleMap-2 {
  width: 100%;
  height: 450px;
  transform: scale(1, 1);
  transition: transform 1s ease;
}

h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 30px;
}

.contact-us-component-heading {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
  text-align: center;
}

.contactMessage {
  font-size: large;
  padding: 5%;
  background-color: #daa5206e;
  box-shadow: 0 -9px 10px rgba(0, 0, 0, 1);
  border-radius: 10px;
  transform: rotateX(1deg) translateZ(30px);
}

.contactMessage2 {
  font-size: 50%;
}

/* chrome desktop mode on phone */
@media (width: 2560px) and (height: 1440px) {
}

/* Skill for Tablet */
@media (min-height: 1024px) and (max-height: 1368px) {
  .contact-us-component-heading {
    font-size: 2.5rem;
    color: #d4af37;
    margin-bottom: 1rem;
    font-family: "Playfair Display", serif;
    text-align: center;
    margin-top: 50px;
  }
}

@media (min-width: 540px) and (max-width: 912px) {
  .contact-us-component-heading {
    font-size: 2.5rem;
    color: #d4af37;
    margin-bottom: 1rem;
    font-family: "Playfair Display", serif;
    text-align: center;
  }
}

/* Contact for mobile */
@media (max-width: 480px) {
  .contact-us-component-heading {
    font-size: 2.5rem;
    color: #d4af37;
    margin-bottom: 1rem;
    font-family: "Playfair Display", serif;
    text-align: center;
  }
}

/* very small screens */
@media (max-width: 350px) {
  .contact-us-component-heading {
    font-size: 2.5rem;
    color: #d4af37;
    margin-bottom: 1rem;
    font-family: "Playfair Display", serif;
    text-align: center;
  }
}
