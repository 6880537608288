/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

/* Hero Section */
.hero-section {
  background-color: #ffffff;
  text-align: center;
  position: relative;
  /* overflow: hidden; */
  padding-bottom: 8vh;
}

.company-title {
  font-family: "Playfair Display", serif;
  font-size: 3rem;
  line-height: 1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  opacity: 0;
  animation: companyTitle 3s forwards;
}

@keyframes companyTitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.company-title .golden {
  color: #d4af37;
  text-shadow: -2.9px 0.2px darkslategrey;
  filter: drop-shadow(0.05em 0.05em 0.1em darkslategrey);
}

.company-title .craft {
  color: #d4af37;
  margin-top: -10px;
  font-size: 2.5rem;
  text-shadow: -2.9px 0.2px darkslategrey;
  filter: drop-shadow(0.05em 0.05em 0.1em darkslategrey);
}

/* Carousel styles */
.home-carousel {
  margin-top: 2rem;
}

.home-carousel .carousel-item {
  /* We leave out display: flex; so as not to interfere with the carousel's sliding behavior */
  height: 20vh;
  background: #151515;
}

/* New inner container for centering content */
.carousel-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.carousel-inner-container p {
  font-size: 1.8rem;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  /* Remove any extra padding to keep the text perfectly centered */
  padding-top: 0;
}

/* About Section */
.about-section {
  padding: 4rem 2rem;
  background: #151515;
  text-align: center;
}

.about-section h2 {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}

.about-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

/* Courses Overview Section */
.courses-overview {
  padding: 4rem 2rem;
  background: #ffffff;
  color: #1e1e1e;
  text-align: center;
}

.courses-overview h2 {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}

.courses-overview ul {
  list-style: none;
  padding: 0;
  max-width: 600px;
  margin: 0 auto;
}

.courses-overview li {
  font-size: 1.2rem;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-bottom: 1px solid #333333;
}

.apply-now-home-link {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
  text-decoration: none;
  transition: all 0.2s ease;
}

.apply-now-home-link:hover {
  font-size: 2.6rem;
  color: #997d22;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
  text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .company-title {
    font-size: 2.5rem;
  }
  .company-title .craft {
    font-size: 2rem;
  }
  .carousel-inner-container p {
    font-size: 1.5rem;
  }
  .about-section h2,
  .courses-overview h2 {
    font-size: 2rem;
  }
}

/* .carousel-indicators {
  display: block !important;
} */

/* Uncomment and style controls if needed:
.carousel-control-next,
.carousel-control-prev { ... } */
