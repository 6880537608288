.footer {
  /* Minimal vertical padding for a shorter footer */
  padding: 5px 0;
  text-align: center;
  font-family: "Arial", sans-serif;
  background-color: #151515;
  color: #d4af37;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px; /* Reduced horizontal padding */
}

.footer-section {
  flex: 1 1 30%;
  margin: 5px 0; /* Minimal margin between sections */
}

.footer-section h3,
.footer-section h4 {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px; /* Smaller font size for compactness */
}

.footer-logo {
  color: #daa520; /* Gold-like emphasis */
  font-size: 16px; /* Slightly smaller logo size */
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin: 2px 0; /* Minimal spacing between list items */
}

.footer-section ul li a {
  text-decoration: none;
  color: #d4af37;
  transition: color 0.3s;
  /* font-size: 12px; */
}

.footer-section ul li a:hover {
  color: #32cd32; /* Light green on hover */
}

.footer-bottom {
  margin-top: 5px;
  border-top: 1px solid #daa520; /* Gold-like border */
  padding-top: 5px;
  font-size: 10px; /* Smaller font size */
}

/* Further reduce spacing on paragraphs */
.footer p {
  margin: 2px 0;
}

/* 
  Make the footer very small on smaller screens 
  (e.g., max-width: 480px). Adjust as needed.
*/
@media (max-width: 480px) {
  .footer {
    padding: 3px 0; /* Even less padding */
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    padding: 0 5px; /* Minimal horizontal padding */
  }

  .footer-section {
    flex: 1 1 100%;
    margin: 3px 0;
    text-align: center;
    font-size: 14px;
    width: 100%;
  }

  .footer-section h3,
  .footer-section h4 {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .footer-section ul {
    /* display: flex;
    justify-content: space-evenly;
    width: 100%; */
  }

  .footer-bottom {
    margin-top: 3px;
    padding-top: 3px;
    font-size: 8px; /* Smaller bottom text */
  }

  .footer p {
    margin: 1px 0;
    font-size: 15px; /* Smaller paragraph text */
  }
}
