/* DRAW QUESTION MARK START */
.faq {
  /* A gold-ish background color */
  background-color: #d9aa3c;
  /* background-image: url(/faq.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* Diamond pattern */
  background-image: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.08) 25%,
      transparent 25%
    ),
    linear-gradient(225deg, rgba(255, 255, 255, 0.08) 25%, transparent 25%),
    linear-gradient(45deg, rgba(255, 255, 255, 0.08) 25%, transparent 25%),
    linear-gradient(315deg, rgba(255, 255, 255, 0.08) 25%, #d9aa3c 25%);
  background-size: 20px 20px;
  background-position:
    0 0,
    0 10px,
    10px -10px,
    -10px 0px;

  position: relative;
  min-height: 400px; /* Enough space to see the pattern and the question mark */
}

/* Big "?" in the center */
.faq::before {
  content: "?";
  position: absolute;
  font-size: 40rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.4);
  pointer-events: none;
}
/* DRAW QUESTION MARK END */

.AboutUs-body {
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  background: #ffff;
}

.carousel-Image {
  object-fit: cover;
  width: 60%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  transform: scale3d(1.5, 1.5, 1.5);
}

.carousel-div {
  width: 1314px;
  height: 458px;
  overflow: hidden;
}

.aboutUs-div {
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 15%;
  margin-right: 15%;
  text-align: center;
  border-left: black solid;
  color: rgb(53, 50, 56);
  transform: rotateY(90deg);
  transform-origin: left;
  background: goldenrod; /* Fallback */
  background:
    linear-gradient(to left bottom, transparent 50%, rgba(0, 0, 0, 0.4) 0)
      no-repeat 100% 0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, #ffd5051a 0);
}

.aboutUs-div-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  margin-top: 3%;
  margin-bottom: 3%;
  padding: 2%;
  text-align: center;
  border-left: #d4af37 solid;
  border-left-width: 10px;
  color: white;
  display: block;
  transform: rotateY(1deg);
  transition: 0.8s ease;
  background-color: #151515 !important;
  background:
    linear-gradient(to left bottom, transparent 50%, #d4af37 0) no-repeat 100%
      0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, #151515 0);
}

.aboutUs-title {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}

/* Faq.css */
.faq-title {
  text-align: center;
  margin-top: 20px;
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}

.faq-question {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  padding: 4%;
  height: 9vh;
  background-color: #ffffff96;
  color: black;
  border-bottom-left-radius: 37%;
}

.faq-icon {
  margin-left: auto;
  transition: transform 0.3s;
}

.expanded {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 4%;
  color: black;
  font-weight: bolder;
  overflow: hidden;
  transition: max-height 0.3s;
}

.faq-answer.show {
  max-height: 200px;
}

.golden-email,
.tel,
.whatsApp {
  color: #004499;
}

.about-downloadForm {
  color: #66aaff;
  animation: pulseColor 2s ease-in-out infinite;
}

@keyframes pulseColor {
  0%,
  100% {
    color: #66aaff;
  }
  50% {
    color: #004499;
  }
}

.about-downloadForm:hover {
  cursor: pointer;
  color: #ff7f50;
}

/* chrome desktop mode on phone */
@media (width: 2560px) and (height: 1440px) {
  .AboutUs-body {
    font-size: -webkit-xxx-large;
    padding-top: 24rem;
    padding-right: unset;
    padding-bottom: 2rem;
    padding-left: unset;
    margin: 10px;
  }
  h2 {
    font-size: 5rem;
  }
  .faq-title {
    font-size: 5rem;
    margin-bottom: 4rem;
  }
}

/* Skill for mobile and Tablet */
@media (max-width: 767px) {
  .AboutUs-body {
    padding-right: unset;
    padding-bottom: 2rem;
    padding-left: unset;
    margin: 10px;
  }

  .professionalExperience {
    padding-top: 1em;
    font-size: 2em;
  }
  .carousel-Image {
    width: 100%;
    transform: none;
  }
  .faq {
    margin-left: 0%;
    margin-right: 0%;
  }
  .faq-question {
    padding: 18% 4%;
  }
}

@media (max-width: 430px) {
  .AboutUs-body {
    padding-right: unset;
    padding-bottom: 2rem;
    padding-left: unset;
    margin: 10px;
  }
  .aboutUs-div {
    height: auto;
    margin-top: 35px;
    margin-left: 0%;
    margin-right: 0%;
    padding: 1em;
  }
  .aboutUs-div-2 {
    height: auto;
    margin-top: 35px;
    margin-left: 0%;
    margin-right: 0%;
    padding: 1em;
  }
}

@media (min-height: 1024px) and (max-height: 1368px) {
}

@media (min-width: 540px) and (max-width: 912px) {
  .AboutUs-body {
    padding-right: unset;
    padding-bottom: 2rem;
    padding-left: unset;
    margin: 10px;
  }
  .aboutUs-div-2 {
    margin-top: 12%;
    margin-bottom: 10%;
    padding: 10px;
  }
}

/* very small screens */
@media (max-width: 350px) {
  .AboutUs-body {
    padding-right: unset;
    padding-bottom: 2rem;
    padding-left: unset;
    margin: 10px;
  }
  .aboutUs-div-2 {
    height: auto;
  }
  .faq-question {
    padding: 29% 4%;
  }
}
