/* ChatBox Container */
.chatbox-container {
  max-width: 600px;
  margin: 2rem auto;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Header (outside the chat bubbles) */
.chatbox-header {
  background-color: #ffffff;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.chatbox-header-title {
  font-size: 1.8rem;
  color: #d4af37;
  margin-bottom: 0.5rem;
}

.chatbox-intro {
  font-size: 1rem;
  color: #555;
  line-height: 1.4;
}

/* Voice Note Link */
.chatbox-voice-note {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.chatbox-voice-note a {
  color: #25d366; /* WhatsApp green */
  text-decoration: none;
  font-weight: bold;
}

.chatbox-voice-note a:hover {
  text-decoration: underline;
}

/* Conversation Area */
.chatbox-conversation {
  max-height: 300px;
  overflow-y: auto;
  padding: 1rem;
  background-color: #151515; /* WhatsApp chat background */
}

/* Message Bubbles */
.chatbox-message {
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 18px;
  font-size: 1rem;
  max-width: 80%;
  word-wrap: break-word;
}

.chatbox-question {
  background-color: #ffffff;
  color: black;
  border: 1px solid #ddd;
  align-self: flex-start;
  margin-right: auto;
}

.chatbox-answer {
  background-color: #acc39a; /* WhatsApp outgoing message color */
  color: black;
  align-self: flex-end;
  margin-left: auto;
}

/* Input Container */
.chatbox-input-container {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  background-color: #ffffff;
  border-top: 1px solid #ddd;
}

.chatbox-input {
  flex-grow: 1;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
}

/* Buttons */
.chatbox-button {
  padding: 0.75rem 1.5rem;
  background-color: #d4af37;
  border: none;
  border-radius: 20px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chatbox-button:hover {
  background-color: #b5942f;
}

.chatbox-final-submit {
  text-align: center;
  padding: 1rem;
}

.chatbox-submit-button {
  padding: 0.75rem 1.5rem;
  background-color: #d4af37;
  border: none;
  border-radius: 20px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chatbox-submit-button:hover {
  background-color: #b5942f;
}

/* Thank You Message Styling */
.chatbox-thank-you {
  font-size: 1.2rem;
  font-weight: bold;
  color: #4caf50; /* A pleasant green */
  text-align: center;
  margin-bottom: 1rem;
}
