/* Container styling (on top of .container from Bootstrap) */
.coursesContainer {
  background: #ffffff; /* white section, like your snippet */
  color: #1e1e1e; /* dark text */
  text-align: center;
  /* padding: 4rem 2rem; */
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
}

/* Main Title */
.coursesTitle {
  font-size: 2.5rem;
  color: #d4af37; /* gold accent */
  margin-bottom: 1.5rem;
  font-family: "Playfair Display", serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Existing styles remain unchanged... */

/* Academy Overview Section */
.academy-overview {
  background-color: #ffffff;
  color: #1e1e1e;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}
.academy-overview h2 {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}
.academy-overview p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}
.academy-overview .apply-now-link {
  font-weight: bold;
  color: #d4af37;
  text-decoration: underline;
}
.academy-overview .apply-now-link:hover {
  color: #b5942f;
}

/* Adjustments for discount notice and other sections can follow below... */

/* Notice / Info box */
.notice {
  /* background: #151515;  */
  color: #ffffff;
  padding: 1.5rem;
  margin: 0 auto 2rem auto;
  border-radius: 6px;
  line-height: 1.6;
  font-size: 1.2rem;
}

.notice-one {
  padding: 1.5rem;
  margin: 0 auto 2rem auto;
  border-radius: 6px;
  line-height: 1.6;
  font-size: 1.2rem;
}

.explore,
.notice-heading {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}

/* Download link */
.downloadForm {
  color: #d4af37;
  cursor: pointer;
  text-decoration: underline;
}
.downloadForm:hover {
  color: #b5942f;
}

/* Beginners & Intermediate "cards" */
.beginners,
.intermediate {
  color: #151515;
  margin: 1rem 0;
  border-radius: 6px;
  padding: 1.5rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

/* Headings within each course card */
.courseHeadings {
  margin-bottom: 1rem;
}

/* Course Title (Beginners / Intermediate) */
.courseTitle {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 2rem;
  font-family: "Playfair Display", serif;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.course-summary-details-container {
  display: flex;
  justify-content: center;
}
.row {
  max-width: 100%;
}

/* Duration emphasis */
.duration {
  font-weight: bold;
}

/* Lists */
.ulParent {
  list-style: none;
  padding: 0;
  max-width: 600px;
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.6;
}
.ulParent > li {
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.ulParent ul {
  list-style: none;
  padding: 0;
  margin-top: 0.5rem;
}
.ulParent ul li {
  border-bottom: 1px solid #333333;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  font-weight: normal;
}

.discount-notice {
  background-color: #151515;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .coursesTitle {
    font-size: 2em;
    margin-top: 10px !important;
    margin-bottom: 33px !important;
  }
}
