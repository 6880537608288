/* ============================
   Courses & Academy Overview
   ============================ */

/* Container styling (on top of .container from Bootstrap) */
.Extra-info-coursesContainer {
  background: #ffffff; /* white section */
  color: #1e1e1e; /* dark text */
  text-align: center;
  /* padding: 4rem 2rem; */
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
}

/* Main Title */
.Extra-info-coursesTitle {
  font-size: 2.5rem;
  color: #d4af37; /* gold accent */
  margin-bottom: 1.5rem;
  font-family: "Playfair Display", serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Academy Overview Section */
.Extra-info-academy-overview {
  background-color: #ffffff;
  color: #1e1e1e;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}
.Extra-info-academy-overview h2 {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}
.Extra-info-academy-overview p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}
.Extra-info-academy-overview .apply-now-link {
  font-weight: bold;
  color: #d4af37;
  text-decoration: underline;
}
.Extra-info-academy-overview .apply-now-link:hover {
  color: #b5942f;
}

/* Notice / Info box */
.Extra-info-notice,
.Extra-info-notice-one {
  color: #ffffff;
  padding: 1.5rem;
  margin: 0 auto 2rem auto;
  border-radius: 6px;
  line-height: 1.6;
  font-size: 1.2rem;
}

/* Headings for discount notice and others */
.Extra-info-explore,
.Extra-info-notice-heading {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}

/* Download link */
.Extra-info-downloadForm {
  color: #d4af37;
  cursor: pointer;
  text-decoration: underline;
}
.Extra-info-downloadForm:hover {
  color: #b5942f;
}

/* Beginners & Intermediate "cards" */
.Extra-info-beginners,
.Extra-info-intermediate {
  color: #151515;
  margin: 1rem 0;
  border-radius: 6px;
  padding: 1.5rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

/* Headings within each course card */
.Extra-info-courseHeadings {
  margin-bottom: 1rem;
}

/* Course Title (Beginners / Intermediate) */
.Extra-info-courseTitle {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 2rem;
  font-family: "Playfair Display", serif;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

/* Course summary container and row adjustments */
.Extra-info-course-summary-details-container {
  display: flex;
  justify-content: center;
}
.Extra-info-row {
  max-width: 100%;
}

/* Duration emphasis */
.Extra-info-duration {
  font-weight: bold;
}

/* Lists styling */
.Extra-info-ulParent {
  list-style: none;
  padding: 0;
  max-width: 600px;
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.6;
}
.Extra-info-ulParent > li {
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.Extra-info-ulParent ul {
  list-style: none;
  padding: 0;
  margin-top: 0.5rem;
}
.Extra-info-ulParent ul li {
  border-bottom: 1px solid #333333;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  font-weight: normal;
}

/* Discount notice (if needed) */
.Extra-info-discount-notice {
  background-color: #151515;
}

/* ============================
       About / Information
   ============================ */

.Extra-info-AboutUs-body,
.Extra-info-Information-body {
  padding-left: 2rem;
  padding-right: 2rem;
  background: #ffff;
}

/* About Us content container - initial and transitioned states */
.Extra-info-aboutUs-div {
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 15%;
  margin-right: 15%;
  text-align: center;
  border-left: black solid;
  color: rgb(53, 50, 56);
  transform: rotateY(90deg);
  transform-origin: left;
  background: goldenrod; /* fallback */
  background:
    linear-gradient(to left bottom, transparent 50%, rgba(0, 0, 0, 0.4) 0)
      no-repeat 100% 0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, #ffd5051a 0);
}
.Extra-info-aboutUs-div-2 {
  display: block;
  margin-top: 3%;
  margin-bottom: 3%;
  padding: 2%;
  text-align: center;
  border-left: #d4af37 solid;
  border-left-width: 10px;
  color: white;
  transform: rotateY(1deg);
  transition: 0.8s ease;
  background-color: #151515 !important;
  background:
    linear-gradient(to left bottom, transparent 50%, #d4af37 0) no-repeat 100%
      0 / 2em 2em,
    linear-gradient(-135deg, transparent 1.5em, #151515 0);
}
.Extra-info-aboutUs-title {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}

/* Carousel Image (if using a carousel) */
.Extra-info-carousel-Image {
  object-fit: cover;
  width: 60%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  transform: scale3d(1.5, 1.5, 1.5);
}
.Extra-info-carousel-div {
  width: 1314px;
  height: 458px;
  overflow: hidden;
}

/* ============================
           FAQ Section
   ============================ */

/* FAQ container with diamond pattern and big question mark */
.Extra-info-faq {
  background-color: #d9aa3c;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.08) 25%,
      transparent 25%
    ),
    linear-gradient(225deg, rgba(255, 255, 255, 0.08) 25%, transparent 25%),
    linear-gradient(45deg, rgba(255, 255, 255, 0.08) 25%, transparent 25%),
    linear-gradient(315deg, rgba(255, 255, 255, 0.08) 25%, #d9aa3c 25%);
  background-size: 20px 20px;
  background-position:
    0 0,
    0 10px,
    10px -10px,
    -10px 0px;
  position: relative;
  min-height: 400px;
}
.Extra-info-faq::before {
  content: "?";
  position: absolute;
  font-size: 40rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.4);
  pointer-events: none;
}

/* FAQ title */
.Extra-info-faq-title {
  text-align: center;
  margin-top: 20px;
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
}

/* FAQ questions and answers */
.Extra-info-faq-question {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  padding: 4%;
  height: 9vh;
  background-color: #ffffff96;
  color: black;
  border-bottom-left-radius: 37%;
}
.Extra-info-faq-icon {
  margin-left: auto;
  transition: transform 0.3s;
}
.Extra-info-expanded {
  transform: rotate(180deg);
}
.Extra-info-faq-answer {
  padding: 4%;
  color: black;
  font-weight: bolder;
  overflow: hidden;
  transition: max-height 0.3s;
}
.Extra-info-faq-answer.show {
  max-height: 200px;
}

/* Contact link colors */
.Extra-info-golden-email,
.Extra-info-tel,
.Extra-info-whatsApp {
  color: #004499;
}

/* Download link animation */
.Extra-info-about-downloadForm {
  color: #66aaff;
  animation: pulseColor 2s ease-in-out infinite;
}
@keyframes pulseColor {
  0%,
  100% {
    color: #66aaff;
  }
  50% {
    color: #004499;
  }
}
.Extra-info-about-downloadForm:hover {
  cursor: pointer;
  color: #ff7f50;
}

/* ============================
         Responsive Adjustments
   ============================ */

/* Large desktop adjustments */
@media (width: 2560px) and (height: 1440px) {
  .Extra-info-AboutUs-body,
  .Extra-info-Information-body {
    font-size: -webkit-xxx-large;
    padding-top: 24rem;
    padding-right: unset;
    padding-bottom: 2rem;
    padding-left: unset;
    margin: 10px;
  }
  h2 {
    font-size: 5rem;
  }
  .Extra-info-faq-title {
    font-size: 5rem;
    margin-bottom: 4rem;
  }
}

/* Mobile & Tablet */
@media (max-width: 767px) {
  .Extra-info-AboutUs-body,
  .Extra-info-Information-body {
    padding-right: unset;
    padding-left: unset;
    margin: 10px;
  }
  .Extra-info-professionalExperience {
    padding-top: 1em;
    font-size: 2em;
  }
  .Extra-info-carousel-Image {
    width: 100%;
    transform: none;
  }
  .Extra-info-faq {
    margin-left: 0%;
    margin-right: 0%;
  }
  .Extra-info-faq-question {
    padding: 18% 4%;
  }
}

/* Small devices */
@media (max-width: 430px) {
  .Extra-info-AboutUs-body,
  .Extra-info-Information-body {
    padding-right: unset;
    padding-left: unset;
    margin: 10px;
  }
  .Extra-info-aboutUs-div,
  .Extra-info-aboutUs-div-2 {
    height: auto;
    margin-top: 35px;
    margin-left: 0%;
    margin-right: 0%;
    padding: 1em;
  }
}

/* Medium screens */
@media (min-width: 540px) and (max-width: 912px) {
  .Extra-info-AboutUs-body,
  .Extra-info-Information-body {
    padding-right: unset;
    padding-left: unset;
    margin: 10px;
  }
}

/* Very small screens */
@media (max-width: 350px) {
  .Extra-info-AboutUs-body,
  .Extra-info-Information-body {
    padding-right: unset;
    padding-left: unset;
    margin: 10px;
  }
  .Extra-info-aboutUs-div-2 {
    height: auto;
  }
  .Extra-info-faq-question {
    padding: 29% 4%;
  }
}

/* Apply Now link */
.Extra-info-information-apply-now-container {
  text-align: center;
  color: black;
}
.Extra-info-information-apply-now-link {
  color: #d9aa3c;
  font-size: 3rem;
  text-decoration: none;
  font-weight: bold;
}
.Extra-info-information-apply-now-link:hover {
  text-decoration: underline;
}
