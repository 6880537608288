/* Container styling */

.heading-verify-text {
  font-size: 1em;
  margin: 5vh auto;

  margin-bottom: 20px;
  text-align: center;
  color: #555;
}

.registration-form-passport-instruction {
  font-size: 1em;

  margin-bottom: 20px;
  text-align: left !important;
  color: #555;
}

.heading-verify-text {
  font-size: 1em;
  margin: 5vh auto;

  margin-bottom: 20px;
  text-align: center;
  color: #555;
}

.verify-container {
  max-width: 400px;
  margin: 5vh auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

/* Heading styling */
.verify-heading {
  font-size: 1.8em;
  margin-bottom: 10px;
  text-align: center;
  color: #333;
}

/* Paragraph text styling */
.verify-text {
  font-size: 1em;
  margin-bottom: 20px;
  text-align: center;
  color: #555;
}

/* Form styling */
.verify-form {
  display: flex;
  flex-direction: column;
}

/* Input field styling */
.verify-input {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}

/* Button styling */
.verify-button {
  background-color: #d4af37; /* Base background color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.verify-button:hover {
  background-color: #bf9e31; /* Darker shade for hover effect */
}

/* Error message styling */
.verify-error {
  margin-top: 15px;
  color: red;
  font-size: 0.9em;
  text-align: center;
}

.registration-choice-container,
.registration-form-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: #1e1e1e;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.registration-choice-container h2,
.registration-form-container h2 {
  font-size: 2.2rem;
  font-weight: bold;
  color: #d4af37;
  text-align: center;
  margin-bottom: 1.5rem;
}

.registration-choice-container p,
.registration-form-container p {
  font-size: 1rem;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 1.5rem;
}

.checkbox-container {
  margin-bottom: 1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.checkbox-container input {
  margin-right: 0.5rem;
}

.button-group {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
  flex-direction: column;
}

.download-button,
.fill-online-button {
  padding: 0.75rem 1.5rem;
  background-color: #d4af37;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
}

.download-button:hover,
.fill-online-button:hover {
  background-color: #b5942f;
  transform: translateY(-2px);
}

.fill-online-button {
  margin-top: 1rem;
}
/* Online form styles */
.registration-form-container form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.registration-form-container h3 {
  color: #d4af37;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea,
.form-group select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.photo-preview {
  max-width: 200px;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}

/* Unique styles for the submit registration button */
.submit-registration-button {
  padding: 0.75rem 1.5rem;
  background-color: #d4af37;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
}

/* Hover effect for the submit registration button */
.submit-registration-button:hover {
  background-color: #b5942f;
  transform: translateY(-2px);
}

/* Disabled state styling */
.submit-registration-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
