.privacy-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: #1e1e1e;
}

.privacy-container h2 {
  font-size: 2rem;
  color: #d4af37;
  text-align: center;
  margin-bottom: 1.5rem;
}

.privacy-container p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* Back Link Styles */
.back-link {
  display: inline-block;
  margin-top: 1.5rem;
  padding: 0.5rem 1rem;
  background-color: #d4af37;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.back-link:hover {
  background-color: #b5942f;
}
