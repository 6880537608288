/* NavBar.css */
.navigation-bar-container {
  background-color: #151515;
}

.brand-logo {
  width: 197px;
  margin-left: 8%;
  margin-top: 8%;
  margin-bottom: 3%;
}

/* Dark Navbar Link Colors */
.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .dropdown-toggle {
  color: #daa520;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .dropdown-toggle:hover,
.navbar-dark .navbar-nav .dropdown-toggle:focus {
  color: #f9e3ab;
}

.dropdown-menu {
  background-color: #dc3545 !important; /* Red "danger" color */
  color: #ffffff !important; /* White text for contrast */
  padding: 0.5rem 1rem; /* Spacing around the button */
  border-radius: 4px; /* Slightly rounded corners */
  transition: background-color 0.3s ease;
}
.dropdown-item {
  color: #ffffff !important;
}
.dropdown-menu:hover,
.dropdown-item:hover {
  background-color: #c82333 !important; /* Red "danger" color */
  color: #ffffff !important;
}
